import { scroller } from 'react-scroll';

export const scrollToElement = element => {
  if (!element) return;
  const offset = typeof window !== 'undefined' && window.innerWidth <= 768 ? 0 : -100;
  scroller.scrollTo(element.replace('#', ''), {
    smooth: true,
    offset,
  });
};

// Insert path into directory tree structure:
const insert = (children, [head = '', ...tail], path = '') => {
  let child = children.find(child => child.path.split('/').pop() === head);
  path = path.endsWith('/') ? `${path}${head}` : `${path}/${head}`;
  if (!child) children.push((child = { path: path, routes: [] }));
  if (tail.length > 0) insert(child.routes, tail, path);
  return children;
};

export const generateTreeView = paths => {
  return paths.map(path => path.split('/').filter(x => x)).reduce((children, parts) => insert(children, parts), []);
};

export const forEach = (array, callback, scope) => {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, array[i], i); // passes back stuff we need
  }
};
